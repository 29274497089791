
//import { IonNav } from '@ionic/vue';
import NavRoot from '@/components/NavRoot.vue';
export default ({
   props: {
        page: { type: String, default: 'copyright' },
    },
   
    //components: { IonNav },
    setup() {
        return { NavRoot  }
    },
    data(){
        return{
            input:'<p style="font-size:3em">loading</p>'
        }

    },
    computed: {
       htmlString() {
          return this.input;
        }
    },

    created(){
       this.loadFile()
       
    },
    methods: {
      loadFile(){
        fetch(this.$store.getters.baseUrl+"/plugin/silencetag/"+this.page+".html")
        .then(response => {
          if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`)
          }
          return response.text()
        })
        .then(html => {
          this.input=html
        
        })
      
        .catch(error => console.log(error))

      }
    }

    
    

});
