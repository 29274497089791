import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_nav = _resolveComponent("ion-nav")!

  return (_openBlock(), _createBlock(_component_ion_nav, {
    root: _ctx.NavRoot,
    rootParams: {notification:_ctx.updateNotification},
    id: "nav"
  }, null, 8, ["root", "rootParams"]))
}