





export const contents = [
    
  { "type": "audio", "index": 1, "name": "Scopri", "img": "/assets/background/scopri.png",  
        "info":[
        {"lang":"it","title":"Scopri" ,"txt":"Sfoglia le pagine per scoprire cosa puoi trovare all'interno del museo"}, 
        {"lang":"en","title":"Discover","txt":"Flip the pages to find out what you can find in the museum"},
        {"lang":"fr","title":"Vous découvrez","txt":"Parcourez les pages pour découvrir ce que vous pouvez trouver à l'intérieur du musée."},
        {"lang":"de","title":"Du entdeckst","txt":"Durchsuchen Sie die Seiten, um herauszufinden, was Sie im Museum finden können"},
        {"lang":"es","title":"Descubres","txt":"Navega por las páginas para descubrir lo que puedes encontrar dentro del museo."},
    ],  },
        
  { "type": "audio", "index": 2, "name": "sale", "img": "/assets/background/sQr.png",  
        "info":[
            {"lang":"it","title":"Ambientati","txt":"In ogni sala inquadra il QR Code e ascolta la voce guida"}, 
            {"lang":"en","title":"Settle in", "txt":"In each room, scan the QR Code and listen to the voice guide."},
            {"lang":"fr","title":"S'installer", "txt":"Dans chaque pièce, scannez le QR Code et écoutez le guide vocal."},
            {"lang":"de","title":"Setz dich ein", "txt":"Scannen Sie in jedem Raum den QR-Code und hören Sie sich die Sprachführung an"},
            {"lang":"es","title":"Establecerse en", "txt":"En cada sala, escanee el código QR y escuche la guía de voz."},
        ] ,  },
    
    { "type": "audio", "index": 3, "name": "Silence", "img": "/assets/background/ondaB.png",   
        "info":[
            {"lang":"it", "title":"Ascolta", "txt":"Quando trovi questo simbolo nella sala premi il pulsante e ascolta."},
            {"lang":"en", "title":"Listen ", "txt":"When you find this symbol in the room press the button and listen."},
            {"lang":"fr", "title":"Écouter", "txt":"Quand vous trouvez ce symbole dans la pièce, appuyez sur le bouton et écoutez."},
            {"lang":"de", "title":"Hören", "txt":"Wenn Sie dieses Symbol im Raum finden, drücken Sie die Taste und hören Sie zu.."},
            {"lang":"es", "title":"Escuchar", "txt":"Cuando encuentres este símbolo en la habitación presiona el botón y escucha."},
        ],  },

    { "type": "audio", "index":4 , "name": "", "img": "/assets/background/quizzs.png",   
        "info":[
            {"lang":"it", "title":"Mettiti alla prova", "txt":"In ogni sala ci sono domande e curiosità, inquadra il QR Code per scoprirle"},
            {"lang":"en", "title":"Challenge yourself ", "txt":"In each room there are questions and curiosities, scan the QR Code to discover them."},
            {"lang":"fr", "title":"Défiez-vous ", "txt":"Dans chaque chambre il y a des questions et des curiosités, scannez le QR Code pour les découvrir."},
            {"lang":"de", "title":"Fordere dich heraus", "txt":"In jedem Raum gibt es Fragen und Kuriositäten, scannen Sie den QR-Code, um sie zu entdecken."},
            {"lang":"es", "title":"Ponte a prueba", "txt":"En cada sala hay preguntas y curiosidades, escanea el Código QR para descubrirlas."}
        ],  },


]

