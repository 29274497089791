
import {
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  modalController,
alertController,
} from '@ionic/vue';
//import { defineComponent } from 'vue';
import NavChild from '@/components/NavChild.vue';
import Lang from '@/components/ChangeLang.vue';
import Download from '@/components/ScaricamentoContenuti.vue';
import Copy from '@/components/Copy.vue';
import Instructions from '@/views/Onboardv3.vue';
import Test from '@/components/TestTag.vue';
import common from '@/js/common';
export default ({
    
    props: {
        titolo: String,
        notification: Boolean
    },
    
    setup(){
        const openHelpModal = async () => {
          
            const top = await modalController.getTop();

            const modal = await modalController.create({
                component: Instructions,
                swipeToClose: true,
                presentingElement: top,
                componentProps: { 
                context:"help",
                
                }
            });


           

            return modal.present();
        };
        return{
            openHelpModal
        }
    },

          
   
    components: {
        IonButtons,
        IonButton,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        
    },
    created(){
        this.networkError=common.networkError;
        this.checkStatus=common.checkOnlineStatus;
         this.emitter.on('fineAggiornamento', _ => {
           this.notificationState=false;
        });
    },

    data: ()=>  {
        return {
        title: "Menu",
        notificationState:false,
        };
    },
    mounted(){
        console.log("NOTIFICATON "+ this.notification);

        this.notificationState=this.notification;
    },
    methods: {
        hide(){
        this.notificationState=false;
        },
        pushPage(page) {
        const ionNav = document.querySelector('ion-nav') as any;
        if(page=="lang"){
            ionNav.push(Lang, { title: 'Changeeee' });
        }else if(page=="aggiorna"){
            ionNav.push(Download,  { lang: localStorage.getItem('lang'), fromC:"update" });
        }else if(page=="copy"){
            ionNav.push(Copy,  {page:"copyright" });
        }else if(page=="privacy"){
            ionNav.push(Copy,  {page:"privacy" });
        }else if(page=="terms"){
            ionNav.push(Copy,  {page:"termini" });
        }else if(page=="help"){
            ionNav.push(Instructions,  {context:"help" });
        }
        else if(page=="test"){
            ionNav.push(Test,{ title: 'Test silence tag' }  );
        }
        else{
            ionNav.push(NavChild, { title: 'Custom Title' });
        }
        
        },
        async dismiss () {
        await modalController.dismiss();
        },

        async showOptions() {
            const online= await this.checkStatus();
            console.log("online STATUS ",online);
            if(online){
                 const alert = await alertController.create({
                    header: this.$t('update.title') ,
                    message: this.$t('update.text') ,
                    buttons: [
                        
                        {
                            text: this.$t('action.postponi') ,
                            role: "cancel",
                            handler: () => {
                                console.log("Declined the offer");
                                
                            },
                        },
                        {
                            text:this.$t('action.download'),
                            handler: () => {
                                console.log("Accepted");
                                this.emitter.emit('aggiorna', "menu");
                                this.pushPage("aggiorna");
                            },
                        },
                    ],
                });

                await alert.present();

            }else{
                this.networkError();
            }
           
        },


        openWebSite(){
            window.open('https://www.engineering-associates.it/', '_blank').focus();
        },
        openCopyPage(){
            window.open('https://www.engineering-associates.it/', '_blank').focus();
        }
    }
});
